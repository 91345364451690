<template>
<div>
  <div><org-change @orgChange="orgChange"/></div>

  <div>
    <div class="container">
      <title-cop  :title="'社区场所店铺企业情况'"/>
      <div class="user_status"><total-count :countList="countList"/></div>
      <div class="split_line"></div>
    </div>

    <div class="container">
      <title-cop  :title="'社区各个场所类型数据统计'"/>
      <div class="peple_change"><width-bar :labelName="placeName" :labelCount="placeList" :dataName="'场所类型统计'" /></div>
    </div>

    <div class="container">
      <title-cop  :title="'社区各个企业类型数据统计'"/>
      <div class="peple_change"><width-bar :labelName="businessName" :labelCount="businessList" :dataName="'企业类型统计'" /></div>
    </div>

    <div class="container">
      <title-cop  :title="'社区各个店铺类型数据统计'"/>
      <div class="peple_change"><width-bar :labelName="shopName" :labelCount="shopList" :dataName="'店铺类型统计'" /></div>
    </div>

  </div>
</div>
</template>

<script>
import orgChange from './components/orgChange.vue'
import SexProgress from './components/sex-progress.vue'
import titleCop from './components/title-cop'
import totalCount from './components/total-count'
import UserType from './components/userType.vue'
import widthBar from './components/widthBar.vue'
import lineCop from './components/line-cop.vue'
import stackBar from './components/stackBar.vue'
export default {
  components: {
    titleCop,
    totalCount,
    orgChange,
    SexProgress,
    widthBar,
    UserType,
    lineCop,
    stackBar
  },
  data () {
    return {
      grid: 0,
      isStreet: true,
      orgList: [],
      resCount: 0,
      countList: [],
      placeName: [],
      placeList: [],
      businessName: [],
      businessList: [],
      shopName: [],
      shopList: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    orgChange (id, isStreet) {
      this.grid = id
      this.isStreet = isStreet
      this.init()
    },
    init () {
      this.getTypeList()
    },
    getTypeList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/statistics/placeCountStreet'),
        method: 'get',
        params: this.$http.adornParams({
          orgId: this.$globalData.userInfo.orgId,
          gridId: this.grid || null
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.countList = [
            {name: '场所', value: data.data.placeNum, icon: require('@/assets/img/statistics/place-statistics.png')},
            {name: '企业', value: data.data.enterpriseNum, icon: require('@/assets/img/statistics/business-statistics.png')},
            {name: '店铺', value: data.data.shopNum, icon: require('@/assets/img/statistics/shop-statistics.png')}
          ]
          this.placeName = data.data.placeCount.map(item => item.labelName)
          this.placeList = data.data.placeCount.map(item => item.labelCount)
          this.businessName = data.data.enterpriseCount.map(item => item.labelName)
          this.businessList = data.data.enterpriseCount.map(item => item.labelCount)
          this.shopName = data.data.shopCount.map(item => item.labelName)
          this.shopList = data.data.shopCount.map(item => item.labelCount)
        } else {
          this.$toast.fail(data.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.topOrgBox {
  padding: 20px 30px;
  .topOrg {
    width: 100%;
    height: 74px;
    text-align: center;
    line-height: 74px;
    margin: 0;
    border-radius: 10px;
    background-color: #fff;
    .pull-icon {
        width: 10px;
    }
  }
}
.container {
  background-color: #fff;
  margin-bottom: 20px;
}
.split_line {
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 2px solid #eee;
}
.user_status {
  //height: 350px;
}
.user_age {
  height: 500px;
}
.peple_change {
  height: 500px;
}
</style>
