<template>
<div class="box">
    <div v-for="(item, index) in countList" :class="index == 0 ? 'content content1' : index == 1 ? 'content content2' : 'content content3'">
      <p class="count_num">{{item.value}}</p>
      <p class="count_tip">{{item.name}}</p>
      <img class="count_img" :src="item.icon" alt="">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    countList: {
      type: Array,
      default () {
        return []
      }
    },
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
ul, li {list-style: none; margin: 0; padding:  0;}
.box {
  display: flex;
  align-items: center;
  margin: 30px 48px;
  .content {
    width: 202px;
    height: 216px;
    background-repeat: no-repeat;
    background-size: 100%;
    .count_img {
      display: block;
      width: 60px;
      height: 60px;
      margin: 0 auto;
    }
    .count_num {
      width: 100%;
      margin: 24px 0 8px;
      font-size: 44px;
      font-family: DINPro-Bold, DINPro;
      font-weight: bold;
      text-align: center;
    }
    .count_tip {
      margin: 0 0 12px;
      font-size: 28px;
      font-family: PingFangSC-Medium, PingFang SC;
      text-align: center;
    }
  }

}
.content1 {
  background-image: url("~@/assets/img/statistics/place-back.png");
  margin-right: 24px;
  color: #F3983C;
}
.content2 {
  background-image: url("~@/assets/img/statistics/business-back.png");
  margin-right: 24px;
  color: #2EC380;
}
.content3 {
  background-image: url("~@/assets/img/statistics/shop-back.png");
  color: #4581F8;
}

</style>
